import React from "react";
import PropTypes from "prop-types";

/**
 * This method returns an react element for SVN pages with aggregate values.
 *
 * @param {object} root .
 * @param {number} root.categoryRating contains aggregate rating.
 * @param {number} root.categoryReviews contains reviews count.
 * @param {string} root.bestRating contains best rating value.
 * @param {string} root.productCategoryName contains best rating value.
 * @param {Function} root.renderContentContainer contains best rating value.
 * @returns {React.element} parsing SVN Pages.
 */
const PLPMicroSiteWithAggregateRating = ({
  categoryRating,
  categoryReviews,
  bestRating,
  productCategoryName,
  renderContentContainer,
}) => {
  return (
    <div itemScope itemType="http://schema.org/Service" className="content-wrapper" data-ga-category="MainContent">
      <meta itemProp="serviceType" content="Gifting" />
      <div itemType="https://schema.org/Product" itemScope>
        {!!productCategoryName && (
          <span itemProp="name" style={{ display: "none" }}>
            {productCategoryName}
          </span>
        )}
        <div id="category-products" className="home-products" style={{ display: "none" }}>
          <div className="category-header category-page">
            <div
              className="category-review-summary"
              itemProp="aggregateRating"
              itemScope
              itemType="https://schema.org/AggregateRating"
            >
              <div className="ratings-count" itemProp="ratingValue" style={{ display: "none" }}>
                {categoryRating}
              </div>
              <div className="ratings-count">
                <span className="category-rating-number">{categoryReviews}</span>
                <div className="stars-outer">
                  <div className="stars-inner" style={{ width: categoryReviews * 2 * 10 }} />
                </div>
              </div>
              <div itemProp="bestRating" style={{ display: "none" }}>
                {bestRating}
              </div>
              <div className="reviews-count" itemProp="reviewCount">
                {categoryReviews}
              </div>
              <span className="aggregated-reviews" style={{ display: "none" }}>
                <span>Reviews</span>
                <i className="arrow-right" />
              </span>
            </div>
          </div>
        </div>
        {renderContentContainer()}
      </div>
    </div>
  );
};

export default PLPMicroSiteWithAggregateRating;

PLPMicroSiteWithAggregateRating.propTypes = {
  categoryRating: PropTypes.number.isRequired,
  categoryReviews: PropTypes.number.isRequired,
  bestRating: PropTypes.string.isRequired,
  productCategoryName: PropTypes.string.isRequired,
  renderContentContainer: PropTypes.func.isRequired,
};
