/**
 * Method to get the price for the DOM actual-price
 *
 * @param {*} ele dom element
 * @param {*} mSiteClass .
 * @param {*} dSiteClass .
 * @returns {*} priceInINR
 */
export const getPriceFromData = (ele, mSiteClass, dSiteClass) => {
  let priceInINR = null;
  try {
    const actualPriceEle = ele.querySelector(mSiteClass);
    if (ele && actualPriceEle) {
      priceInINR = actualPriceEle.innerHTML;
    } else {
      priceInINR = ele.parentElement.querySelector(dSiteClass)?.innerHTML;
    }
    return priceInINR.replace(/^[^\d]*/, "").replace(/,/g, "");
  } catch (e) {
    return priceInINR;
  }
};

/**
 *
 * Method to convert price from INR
 *
 * @param {*} price price
 * @param {*} conversionFactor conversion factor
 * @returns {*} local
 */
export const convertPriceFromINR = (price, conversionFactor) => {
  const factor = conversionFactor && conversionFactor.toString().replace(/,/g, "");
  let localPrice = price && price.toString().replace(/,/g, "");
  localPrice = (parseFloat(localPrice) / parseFloat(1.0)).toFixed(2);
  const local = (parseFloat(localPrice) * parseFloat(factor)).toFixed(2);
  return local;
};

/**
 * This functions handles the currency conversion in all the micro-site for the mobile by taking required inputs as a param
 *
 * @param {object} ele dom element
 * @param {string} localPrice price
 * @param {string} currencyUom currency symbol
 * @param {string} conversionFactor conversionFactor of the currency
 * @param {string} currencySymbol current currency symbol
 */
const appendPriceInMobile = (ele, localPrice, currencyUom, conversionFactor, currencySymbol) => {
  let webPriceString = "";
  let price = localPrice;
  if (currencyUom === "INR") {
    price = Math.round(localPrice);
  }
  const eleToBeUpdated = ele;
  if (ele.classList.contains("recentlyPrice")) {
    webPriceString = `<span class="pricing_m-currency__2Dq2s WebCurrency" itemprop="priceCurrency">${currencySymbol}</span><span class="pricing_m-selling-price__3pukp actualPrice" itemprop="price">${price}</span>`;
  } else if (ele.classList.contains("recentlyPriceMRP")) {
    const discountEle = ele.getElementsByClassName("widget-price-discount");
    webPriceString = `<span class="widget-price-mrp-currency-symbol">${currencySymbol}</span><span class="widget-price-mrp" itemprop="price">${price}</span
      ><span class="widget-price-discount">${discountEle[0].innerHTML}</span>`;
    if (currencyUom === "INR") {
      // eslint-disable-next-line no-param-reassign
      ele.style.display = "inline-block";
    } else {
      // eslint-disable-next-line no-param-reassign
      ele.style.display = "none";
    }
  } else {
    if (!(ele && ele.querySelector(".actual-price-block"))) {
      webPriceString = `${webPriceString} <div class='actual-price-block' style='display:none'>`;
      webPriceString = `${webPriceString} <span class='actual-currency'>INR</span>`;
      if (ele.querySelector(".value")) {
        webPriceString = `${webPriceString} <span class='actual-price'>  ${
          ele.querySelector(".value").innerHTML
        }  </span>`;
      }
      if (ele.querySelector(".old-price")) {
        webPriceString = `${webPriceString} <span class='actual-old-price'> ${
          ele.querySelector(".old-price").innerHTML
        } </span>`;
      }
      webPriceString = `${webPriceString} </div>`;
    } else {
      webPriceString = `${webPriceString} <div class='actual-price-block' style='display:none'>`;
      if (ele && ele.querySelector(".actual-price-block")) {
        webPriceString = `${webPriceString} ${ele.querySelector(".actual-price-block").innerHTML}`;
      }
      webPriceString = `${webPriceString} </div>`;
    }
    if (currencyUom === "INR") {
      webPriceString = `${webPriceString} <span class='ind-rupee'>${currencySymbol}</span>`;
    } else {
      webPriceString = `${webPriceString} <span class='other-currencies'>${currencySymbol}</span>`;
    }
    webPriceString = `${webPriceString} <span class='value'> ${price} </span>`;
    if (ele && ele.querySelector(".actual-old-price")) {
      let crossPrice = convertPriceFromINR(getPriceFromData(ele, ".actual-old-price", ""), conversionFactor);
      if (parseFloat(crossPrice)) {
        const listPriceEle = ele.parentElement?.parentElement?.querySelector(".percentoff");
        const offEle = listPriceEle.querySelector(".percent")?.innerHTML;
        let newListPriceDom = `<span class="listprice old-price">`;
        if (currencyUom === "INR") {
          crossPrice = Math.round(crossPrice);
          newListPriceDom = `${newListPriceDom} <span class='ind-rupee'>${currencySymbol}</span>`;
        } else {
          newListPriceDom = `${newListPriceDom} <span class='other-currencies'>${currencySymbol}</span>`;
        }
        newListPriceDom = `${newListPriceDom} ${crossPrice} </span> <span class="percent"> ${offEle} </span>`;
        listPriceEle.innerHTML = newListPriceDom;
      }
    }
  }
  if (eleToBeUpdated) {
    eleToBeUpdated.innerHTML = webPriceString;
  }
};
/**
 * This functions handles the currency conversion in all the micro-site for the desktop by taking required inputs as a param
 *
 * @param {object} ele dom element
 * @param {string} localPrice price
 * @param {string} currencyUom currency symbol
 * @param {string} conversionFactor conversionFactor of the currency
 * @param {string} currencySymbol current currency symbol
 */
const appendPriceInDesktop = (ele, localPrice, currencyUom, conversionFactor, currencySymbol) => {
  let webPriceStr = "";
  let price = localPrice;
  const eleToBeUpdated = ele;
  if (currencyUom === "INR") {
    price = Math.round(localPrice);
  }
  if (ele.classList.contains("recentlyPrice")) {
    webPriceStr = `<span class="widget-currency-symbol WebCurrency" itemprop="priceCurrency">${currencySymbol}</span>
                   <span class="widget-currency-price actualPrice" itemprop="price">${price}</span>`;
  } else if (ele.classList.contains("recentlyPriceMRP")) {
    const discountElement = ele.getElementsByClassName("widget-price-discount mrp-text");
    webPriceStr = `<span class="widget-price-mrp-currency-symbol mrp-text">${currencySymbol}</span><span class="widget-price-mrp mrp-text" itemprop="price">${price}</span
    ><span class="widget-price-discount mrp-text">${discountElement[0].innerHTML}</span>`;
  } else {
    let currClass = "WebRupee";
    webPriceStr = `<meta itemprop="priceCurrency" content="${currencyUom}">`;
    webPriceStr = `${webPriceStr}<meta itemprop="price" content="${price}">`;
    if (currencyUom !== "INR") {
      currClass = `curr_${currencyUom.toLowerCase()}`;
    }

    webPriceStr = `${webPriceStr}<span class="${currClass}">${currencySymbol}&nbsp;</span>${price}</span>`;
    if (ele.parentNode.querySelector(".oldpriceInINR")) {
      const oldPriceEle = ele.parentNode.querySelector(".oldpriceInINR");
      let oldPriceLocal = convertPriceFromINR(getPriceFromData(oldPriceEle[0]), conversionFactor);
      if (currencyUom === "INR") {
        oldPriceLocal = Math.round(oldPriceLocal);
      }
      webPriceStr += "<span class='offer-and-oldprice'>";
      webPriceStr += '<span class="oldprice" style="text-decoration: line-through;">';
      webPriceStr = `${webPriceStr}<span class="${currClass}">${currencySymbol}</span>${oldPriceLocal}</span>`;
      if (ele.querySelector(".off")) {
        webPriceStr += `<span class='off'>${ele.querySelector(".off").innerHTML}</span>`;
      }
      webPriceStr += "</span>";
    }
  }

  if (eleToBeUpdated) {
    eleToBeUpdated.innerHTML = webPriceStr;
  }
};

/**
 * Method to update price DOM
 *
 * @param {*} ele dom element
 * @param {*} price price
 * @param {*} currencyUom currencyUom
 * @param {*} conversionFactor conversionFactor
 * @param {string} currencySymbol current currency symbol
 */
export const updateCurrencyDetails = (ele, price, currencyUom, conversionFactor, currencySymbol) => {
  if (typeof window !== "undefined" && !window.dSite) {
    appendPriceInMobile(ele, price, currencyUom, conversionFactor, currencySymbol);
  } else {
    appendPriceInDesktop(ele, price, currencyUom, conversionFactor, currencySymbol);
  }
};

/**
 * Method to get all webprice element and start updating dom
 *
 * @param {*} currencyFormat currencyFormat
 * @param {*} currencyUom currencyUom
 */
export const convertComponentCurrency = (currencyFormat, currencyUom) => {
  if (typeof currencyFormat !== "undefined") {
    let conversionFactor = currencyFormat.replace(/^[^\d]*/, "");
    conversionFactor = conversionFactor.replace(",", "");
    const currencySymbol = currencyFormat.replace(conversionFactor, "");

    const eleList = document.querySelectorAll(`.webprice`) || "<p></p>";
    eleList.forEach((ele) => {
      if (!ele.classList.contains(".offer-and-oldprice")) {
        const localPrice = convertPriceFromINR(getPriceFromData(ele, ".actual-price", ".priceInINR"), conversionFactor);
        updateCurrencyDetails(ele, localPrice, currencyUom, conversionFactor, currencySymbol);
      }
    });
  }
};
