import { getPlatform, waitForElm } from "../common";
import { options, delayTime } from "./impressionOptionConfig";
/**
 * Banner impressions
 *
 */
const widgetImpressionFun = () => {
  let scrollTimeout;
  let widgetPayload = [];
  let widg = {};
  widg.attr = [];
  let widgetName = "";
  /**
   *
   */
  function applyWidgetChanges() {
    window?.cdp?.callToApi({ eventList: widgetPayload }, "widgetdisplay");
    widgetPayload = [];
    widg = {};
    widg.attr = [];
    widgetName = "";
  }

  /**
   * this function return object
   *
   * @param {object} entry Intrected Element
   * @returns {object} Intrected element object form.
   */
  function observeElm(entry) {
    const targetElm = entry.target;
    const widgetInfoObj = {};
    const parentElm = targetElm.parentElement;
    const getIdPriceIndex =
      getPlatform() === "DSITE" ? parentElm?.getAttribute("data-wdgt") : targetElm?.getAttribute("data-wdgt");
    const parts = getIdPriceIndex.split("~");
    const [id, pri, pos] = parts;
    widgetInfoObj.id = id;
    widgetInfoObj.pri = pri;
    widgetInfoObj.pos = pos;
    widgetInfoObj.label =
      getPlatform() === "DSITE"
        ? parentElm.getAttribute("widget-pname").trim()
        : targetElm.getAttribute("widget-pname").trim();
    return widgetInfoObj;
  }
  /**
   * This method is called for widget impression handling.
   *
   * @param {object} entries event object of observer.
   */
  function onInterSectWidget(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio >= 0.7) {
        const targetElm = entry.target;
        if (getPlatform() === "DSITE" && widgetName === "") {
          widgetName = targetElm?.closest(".widgetImpressionPOC")?.querySelector("h3")?.textContent;
        } else if (getPlatform() === "MSITE" && widgetName === "") {
          widgetName = targetElm?.closest(".widgetImpressionPOC")?.querySelector("h2")?.textContent;
        }

        widg.attr.push(observeElm(entry));
      } else {
        const notInView = observeElm(entry);
        const objIndex = widg.attr.findIndex((obj) => {
          return obj.id === notInView.id;
        });
        if (objIndex !== -1) {
          widg.attr.splice(objIndex, 1);
        }
      }
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        if (widg.attr.length > 0) {
          widg.pagename = window.fnpPageType;
          widg.propertyname = widgetName.trim();
          widg.type = "product";
          widgetPayload.push(widg);
          applyWidgetChanges();
        }
      }, delayTime);
    });
  }

  /** */
  function handleScroll() {
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => {
      if (widg.attr.length > 0) {
        widg.pagename = window.fnpPageType;
        widg.propertyname = widgetName.trim();
        widg.type = "product";
        widgetPayload.push(widg);
        applyWidgetChanges();
      }
    }, delayTime);
  }
  document.addEventListener("scroll", handleScroll);

  waitForElm(".widgetImpressionPOC .widgetItemContainer .widget-card").then(() => {
    setTimeout(() => {
      const widgetImpression = document.querySelectorAll(".widgetImpressionPOC .widgetItemContainer .widget-card");
      const observer = new IntersectionObserver(onInterSectWidget, options);
      widgetImpression?.forEach((elm) => {
        observer.observe(elm);
      });
    }, 1000);
  });
};
export default widgetImpressionFun;
