import { parseCookies } from "nookies";

const { impressionDelayTime } = parseCookies();

export const options = {
  root: null,
  rootMargin: "0px",
  threshold: [0, 0.7],
};

export const delayTime = impressionDelayTime ? Number(impressionDelayTime) : 2000;
